$white: #FFFFFF;
$whitePlaceholder: #ffffffd2;

$torchRed: #FB1A50;
$torchRedHover: #FB1A40dC;

$wildBlueYonder: #8190B6;
$wildBlueYonderHover: #A5bAEC;

$ebony: #0E1424;
$ebonyBurgerBg: #1B243D;

$kashmirBlue: #526595;
$rhino: #2A3656;
$bigStone: #19223A;
$bigStoneLight: #161E34;
$bigStoneLightFocus: #1C2642;
$sanJuan: #33436D;
$sanJuanHigh: #2C395B;
$sanJuanHighHover: #384464;