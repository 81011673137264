@import 'tools/styles/adaptive';

.people1 {
    position: absolute;
}

.people2 {
    position: absolute;
    z-index: 1;
}

.people3 {
    position: absolute;
}

.grayLine1 {
    position: absolute;
    z-index: 2;
}

.grayLine2 {
    position: absolute;
}

.net {
    position: absolute;
}

.symbols {
    position: absolute;
    z-index: 1;
}

.grayCorner1 {
    position: absolute;
}

.grayCorner2 {
    position: absolute;
}

.grayCorner3 {
    position: absolute;
}

.grayCorner4 {
    position: absolute;
}

@include applyResponsive320 {
    .people1 {
        top: 103px;
        right: 143px;

        img {
            width: 143px;
            height: 143px;
        }
    }

    .people2 {
        top: 171px;
        left: 0;

        img {
            width: 136px;
            height: 30px;
        }
    }

    .people3 {
        top: 37px;
        left: 52px;

        img {
            width: 167px;
            height: 33px;
        }
    }

    .grayLine1 {
        display: none;
    }

    .grayLine2 {
        display: none;
    }

    .net {
        width: 112px;
        height: 85px;

        top: 152px;
        left: 18px;
    }

    .symbols {
        width: 94px;
        height: 45px;

        top: -2px;
        left: 177px;
    }

    .grayCorner1 {
        display: none;
    }

    .grayCorner2 {
        display: none;
    }

    .grayCorner3 {
        display: none;
    }

    .grayCorner4 {
        display: none;
    }
}

@include applyResponsive428 {
    .people1 {
        top: 119px;
        right: 173px;

        img {
            width: 173px;
            height: 173px;
        }
    }

    .people2 {
        top: 196px;
        left: 0;

        img {
            width: 176px;
            height: 39px;
        }
    }

    .people3 {
        top: 44px;
        left: 79px;

        img {
            width: 187px;
            height: 37px;
        }
    }

    .net {
        width: 141px;
        height: 107px;

        top: 180px;
        left: 28px;
    }

    .symbols {
        width: 114px;
        height: 55px;

        top: -2px;
        left: 227px;
    }
}

@include applyResponsive512 {
    .people1 {
        top: 145px;
        right: 193px;

        img {
            width: 193px;
            height: 193px;
        }
    }

    .people2 {
        top: 232px;
        left: 0;

        img {
            width: 196px;
            height: 44px;
        }
    }

    .net {
        width: 200px;
        height: 153px;

        top: 169px;
        left: 50px;
    }
}

@include applyResponsive768 {
    .people1 {
        top: 196px;
        right: 233px;

        img {
            width: 233px;
            height: 233px;
        }
    }

    .people2 {
        top: 319px;
        left: 126px;

        img {
            width: 220px;
            height: 49px;
        }
    }

    .people3 {
        top: 44px;
        left: 79px;

        img {
            width: 282px;
            height: 56px;
        }
    }

    .net {
        width: 258px;
        height: 197px;

        top: 226px;
        left: 55px;
    }

    .symbols {
        width: 240px;
        height: 116px;

        top: 147px;
        left: auto;
        right: 10px;
    }
}

@include applyResponsive1024 {
    .people1 {
        top: 20px;
        right: 381px;

        img {
            width: 330px;
            height: 330px;
        }
    }

    .people2 {
        top: 358px;
        left: 91px;

        img {
            width: 240px;
            height: 54px;
        }
    }

    .people3 {
        top: 51px;
        left: 282px;

        img {
            width: 337px;
            height: 67px;
        }
    }

    .grayLine1 {
        display: block;

        width: 208px;
        height: 247px;

        top: 75px;
        left: 45px;
    }

    .grayLine2 {
        display: block;

        width: 50px;
        height: 60px;

        top: 400px;
        right: 160px;
    }

    .net {
        width: 262px;
        height: 200px;

        top: 291px;
        left: 154px;
    }

    .symbols {
        width: 223px;
        height: 108px;

        top: 368px;
        right: auto;
        left: 136px;
    }

    .grayCorner1 {
        display: block;

        width: 93px;
        height: 87px;

        top: -5px;
        left: -5px;
    }

    .grayCorner2 {
        display: block;

        width: 93px;
        height: 87px;

        top: -5px;
        right: -5px;
    }

    .grayCorner3 {
        display: block;

        width: 93px;
        height: 87px;

        top: 456px;
        left: -5px;
    }

    .grayCorner4 {
        display: block;

        width: 93px;
        height: 87px;

        top: 456px;
        right: -5px;
    }
}

@include applyResponsive1280 {
    .people1 {
        top: 53px;
        right: 450px;

        img {
            width: 390px;
            height: 390px;
        }
    }

    .people2 {
        top: 447px;
        left: 172px;
    }

    .people3 {
        top: 88px;
        left: 331px;
    }

    .grayLine1 {
        top: 106px;
        left: 60px;
    }

    .grayLine2 {
        top: 506px;
        right: 194px;
    }

    .net {
        width: 333px;
        height: 254px;

        top: 332px;
        left: 54px;
    }

    .symbols {
        width: 258px;
        height: 124px;

        top: 433px;
        left: 36px;
    }

    .grayCorner3 {
        top: 556px;
    }

    .grayCorner4 {
        top: 556px;
    }
}

@include applyResponsive1440 {
    .people1 {
        top: 53px;
        right: 550px;

        img {
            width: 420px;
            height: 420px;
        }
    }

    .people2 {
        top: 471px;
        left: 337px;
    }

    .people3 {
        top: 94px;
        left: 416px;
    }

    .grayLine1 {
        top: 106px;
        left: 120px;
    }

    .grayLine2 {
        top: 506px;
        right: 194px;
    }

    .net {
        width: 365px;
        height: 279px;

        top: 370px;
        left: 142px;
    }

    .symbols {
        top: 483px;
        left: 121px;
    }

    .grayCorner3 {
        top: 656px;
    }

    .grayCorner4 {
        top: 656px;
    }
}

@include applyResponsive1920 {
    .people1 {
        top: 64px;
        right: 637px;

        img {
            width: 497px;
            height: 497px;
        }
    }

    .people2 {
        top: 514px;
        left: 337px;
    }

    .people3 {
        top: 99px;
        left: 540px;
    }

    .grayLine1 {
        top: 116px;
        left: 142px;
    }

    .grayLine2 {
        top: 609px;
        right: 353px;
    }

    .net {
        width: 387px;
        height: 296px;

        top: 424px;
        left: 131px;
    }

    .symbols {
        top: 558px;
        left: 104px;
    }

    .grayCorner3 {
        top: 703px;
    }

    .grayCorner4 {
        top: 703px;
    }
}