@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.projectCard {
    @include dFlex();
    @include flexColumn();
    @include justifyContentStart();
    @include alignItemsCenter();

    border: 2px solid $sanJuan;
    border-radius: 10px;
    background: $ebony;

    z-index: 1;

    .icon {}

    .description {
        @include fontPlay700();
        text-transform: uppercase;
        text-align: center;
    }
}

@include applyResponsive320 {
    .projectCard {
        width: 100%;
        height: 365px;

        padding: 55px 30px;

        .icon {
            padding-bottom: 46px;

            img {
                width: 114px;
                height: 114px;
            }
        }

        .description {
            font-size: 23px;
            line-height: 27px;
        }
    }
}

@include applyResponsive428 {
    .projectCard {
        padding: 55px;

        .description {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

@include applyResponsive768 {
    .projectCard {
        width: 379px;
        height: 368px;
    }
}

@include applyResponsive1024 {
    .projectCard {
        width: 270px;
        height: 310px;

        padding: 40px;

        .icon {
            img {
                width: 100px;
                height: 100px;
            }
        }

        .description {
            font-size: 18px;
            line-height: 22px;
        }
    }
}

@include applyResponsive1440 {
    .projectCard {
        width: 369px;
        height: 368px;

        padding: 55px;

        .icon {
            img {
                width: 115px;
                height: 115px;
            }
        }

        .description {
            font-size: 24px;
            line-height: 28px;
        }
    }
}