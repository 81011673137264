@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';
@import 'tools/styles/animations';

.buttonForm {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();

    width: 100%;

    color: $white;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    background: $sanJuanHigh;
    border: 2px solid $sanJuan;

    img {
        margin-left: 18px;
    }

    &:hover {
        background: $sanJuanHighHover;
        border: 2px solid $sanJuanHighHover;
        @include easeTransition();
    }
}

@include applyResponsive320 {
    .buttonForm {
        height: 47px;

        font-size: 14px;

        border-radius: 7px;
        padding: 0 23px;
    }
}

@include applyResponsive1024 {
    .buttonForm {
        height: 56px;

        font-size: 16px;

        border-radius: 8px;
        padding: 0 26px;
    }
}

@include applyResponsive1440 {
    .buttonForm {
        height: 66px;

        font-size: 20px;

        border-radius: 10px;
        padding: 0 32px;
    }
}