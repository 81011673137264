@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.mainBlock {
    position: relative;

    width: 100%;

    @include fontPlay700();
    text-transform: uppercase;

    .upperTextSection {
        @include dFlex();
        @include flexRow();
        @include justifyContentStart();
        @include alignItemsStart();

        .upperDate {
            font-size: 14px;
            line-height: 16px;

            z-index: 2;
        }

        .red {
            color: $torchRed;
        }

        .gray {
            color: $kashmirBlue;
        }
    }

    .titleContainer {
        @include dFlex();

        .title {
            z-index: 2;

            .red {
                color: $torchRed;
            }
        }
    }

    .centralTextSection {
        @include dFlex();
        @include flexColumn();

        .line {
            height: 1px;
            background: $white;

            z-index: 2;
        }

        .lowerDate {
            margin-right: 12px;
            z-index: 2;
        }
    }

    .bottomTextSection {
        @include dFlex();
        @include flexColumn();
        @include justifyContentCenter();
        @include alignItemsCenter();

        .white {
            z-index: 1;
        }

        .arrow {}
    }
}

@include applyResponsive320 {
    .mainBlock {
        padding: 12px;
        padding-top: 18px;
        padding-right: 0;

        margin-bottom: 35px;

        .upperTextSection {
            .upperDate {
                display: none !important;
            }

            .red {
                font-size: 25px;
                line-height: 30px;
            }

            .gray {
                font-size: 8px;
                line-height: 7px;

                margin-top: 6px;
                margin-left: 4px;
            }
        }

        .titleContainer {
            @include justifyContentStart();
            margin-bottom: 38px;

            .title {
                font-size: 43px;
                line-height: 52px;
            }
        }

        .centralTextSection {
            @include alignItemsEnd();
            margin-bottom: 43px;

            .line {
                width: 170px;
                margin-bottom: 16px;
            }

            .lowerDate {
                font-size: 9px;
                line-height: 11px;
            }
        }

        .bottomTextSection {
            padding-right: 12px;

            .white {
                font-size: 14px;
                line-height: 17px;

                margin-bottom: 8px;
            }

            .arrow {
                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

@include applyResponsive428 {
    .mainBlock {
        padding: 22px;
        padding-top: 18px;
        padding-right: 0;

        margin-bottom: 31px;

        .upperTextSection {
            .red {
                font-size: 34px;
                line-height: 40px;
            }

            .gray {
                font-size: 10px;
                line-height: 9px;

                margin-top: 9px;
                margin-left: 6px;
            }
        }

        .titleContainer {
            .title {
                font-size: 52px;
                line-height: 60px;
            }
        }

        .centralTextSection {
            .line {
                width: 211px;
            }

            .lowerDate {
                font-size: 10px;
                line-height: 12px;
            }
        }

        .bottomTextSection {
            padding-right: 22px;

            .white {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }
}

@include applyResponsive512 {
    .mainBlock {
        .titleContainer {
            @include justifyContentCenter();

            .title {
                font-size: 69px;
                line-height: 75px;
            }
        }

        .centralTextSection {
            .line {
                width: 231px;
            }
        }

        .bottomTextSection {
            .white {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }
}

@include applyResponsive768 {
    .mainBlock {
        .titleContainer {
            .title {
                font-size: 106px;
                line-height: 115px;
            }
        }

        .centralTextSection {
            .line {
                width: 287px;
            }
        }
    }
}

@include applyResponsive1024 {
    .mainBlock {
        padding: 50px;
        padding-top: 18px;

        margin-bottom: 130px;

        .upperTextSection {
            .upperDate {
                @include dFlex();
                margin-top: 13px;
                margin-right: 150px;
            }

            .red {
                font-size: 42px;
            }

            .gray {
                font-size: 13px;

                margin-top: 9px;
                margin-left: 10px;
            }
        }

        .titleContainer {
            margin-bottom: 43px;

            .title {
                line-height: 120px;
            }
        }

        .centralTextSection {
            @include alignItemsCenter();
            margin-bottom: 48px;

            .line {
                margin-bottom: 0;
            }

            .lowerDate {
                display: none !important;
            }
        }

        .bottomTextSection {
            .white {
                font-size: 21px;
                margin-bottom: 12px;
            }

            .arrow {
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@include applyResponsive1280 {
    .mainBlock {
        padding: 52px;
        margin-bottom: 171px;

        .upperTextSection {
            .upperDate {
                margin-right: 190px;
            }

            .red {
                font-size: 47px;
                line-height: 50px;
            }

            .gray {
                font-size: 15px;
                margin-top: 10px;
            }
        }

        .titleContainer {
            .title {
                font-size: 130px;
                line-height: 145px;
            }
        }

        .bottomTextSection {
            .white {
                font-size: 24px;
                line-height: 27px;

                margin-bottom: 16px;
            }
        }
    }
}

@include applyResponsive1440 {
    .mainBlock {
        padding: 130px;
        padding-top: 52px;

        margin-bottom: 214px;

        .upperTextSection {
            .upperDate {
                margin-right: 190px;
            }

            .red {
                font-size: 51px;
                line-height: 59px;
            }

            .gray {
                font-size: 14px;
                line-height: 16px;

                margin-top: 12px;
            }
        }

        .titleContainer {
            .title {
                font-size: 140px;
                line-height: 160px;
            }
        }
    }
}

@include applyResponsive1920 {
    .mainBlock {
        .upperTextSection {
            .upperDate {
                margin-right: 300px;
            }

            .red {
                font-size: 61px;
                line-height: 71px;
            }

            .gray {
                font-size: 15px;
                margin-top: 13px;
            }
        }

        .titleContainer {
            .title {
                font-size: 160px;
                line-height: 185px;
            }
        }
    }
}