@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.client {
    @include dFlex();
    @include flexColumn();
    @include justifyContentBetween();
    @include alignItemsCenter();

    z-index: 1;

    .number {}

    .logo {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .name {
        white-space: nowrap;
    }
}

@include applyResponsive320 {
    .client {
        height: 174px;

        .number {
            font-size: 11px;
            line-height: 13px;
        }

        .name {
            font-size: 11px;
            line-height: 13px;
        }

        .logo {
            img {
                max-width: 220px;
                max-height: 90px;
            }
        }
    }
}

@include applyResponsive512 {
    .client {
        height: 200px;

        .number {
            font-size: 15px;
            line-height: 17px;
        }

        .name {
            font-size: 15px;
            line-height: 17px;
        }

        .logo {
            img {
                max-width: 270px;
                max-height: 100px;
            }
        }
    }
}

@include applyResponsive1024 {
    .client {
        width: 240px;
        font-size: 14px;

        .number {
            font-size: 14px;
            line-height: 16px;
        }

        .name {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

@include applyResponsive1440 {
    .client {
        width: 380px;
        height: 303px;

        .number {
            font-size: 20px;
            line-height: 23px;
        }

        .name {
            font-size: 16px;
            line-height: 18px;
        }

        .logo {
            img {
                max-width: 380px;
                max-height: 200px;
            }
        }
    }
}

@include applyResponsive1920 {
    .client {
        width: 450px;
        height: 303px;

        .logo {
            img {
                max-width: 450px;
            }
        }
    }
}