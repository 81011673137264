@import 'tools/styles/adaptive';
@import 'tools/styles/flex';

.device1 {
    position: absolute;
    z-index: 1;
}

.device2 {
    position: absolute;
    z-index: 1;
}

.redHatch {
    position: absolute;
}

@include applyResponsive320 {
    .device1 {
        width: 112px;
        height: 110px;

        top: -11px;
        left: -61px;
    }

    .device2 {
        width: 67px;
        height: 61px;

        top: 11px;
        right: -10px;
    }

    .redHatch {
        @include dFlex();
        @include justifyContentCenter;

        width: 100%;
        height: 100%;

        max-width: 342px;
        max-height: 27px;

        top: 490px;

        padding-left: 5px;
    }
}

@include applyResponsive428 {
    .device1 {
        top: -14px;
        left: -49px;
    }

    .device2 {
        top: 20px;
        right: -10px;
    }

    .redHatch {
        display: block;

        width: 375px;
        height: 27px;

        top: 504px;
        right: 0;

        margin: 0;
        padding: 0;
    }
}

@include applyResponsive512 {
    .device1 {
        top: -21px;
        left: 8px;
    }

    .device2 {
        top: 55px;
        right: 22px;
    }

    .redHatch {
        @include dFlex();
        @include justifyContentCenter();

        right: auto;
        margin-left: 115px;
    }
}

@include applyResponsive768 {
    .device1 {
        width: 105px;
        height: 95px;

        top: -18px;
        left: 64px;
    }

    .device2 {
        width: 123px;
        height: 112px;

        top: 103px;
        right: 73px;
    }

    .redHatch {
        top: 591px;
        margin-left: 268px;
    }
}

@include applyResponsive1024 {
    .device1 {
        width: 130px;
        height: 127px;

        top: -24px;
        left: 234px;
    }

    .device2 {
        width: 90px;
        height: 82px;

        top: 171px;
        left: 313px;
    }

    .redHatch {
        display: block;

        width: 250px;
        height: 20px;

        top: 250px;
        left: -4px;

        margin: 0;
    }
}

@include applyResponsive1280 {
    .device1 {
        width: 117px;
        height: 114px;

        top: -36px;
        left: 234px;
    }

    .device2 {
        width: 80px;
        height: 72px;

        right: auto;
        top: 187px;
        left: 362px;
    }

    .redHatch {
        width: 277px;
        height: 22px;

        top: 249px;
        left: -5px;
    }
}

@include applyResponsive1440 {
    .device1 {
        width: 159px;
        height: 157px;

        top: -49px;
        left: 266px;
    }

    .device2 {
        width: 122px;
        height: 112px;

        top: 282px;
        left: 393px;
    }

    .redHatch {
        width: 334px;
        height: 27px;

        top: 364px;
        left: 1px;
    }
}

@include applyResponsive1920 {
    .device1 {
        top: -43px;
        left: 345px;
    }

    .device2 {
        top: 285px;
        left: 462px;
    }

    .redHatch {
        top: 355px;
        left: 0;
    }
}