@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.requestForm {
    @include dFlex();
    @include flexColumn();
    @include justifyContentStart();
    @include alignItemsCenter();

    background: $ebony;
    border: 2px solid $sanJuan;
    border-radius: 10px;

    z-index: 2;

    .sendButton {
        margin-bottom: 24px;
    }

    .warning {
        color: $wildBlueYonder;
        line-height: normal;

        .bold {
            @include fontPlay700();
        }
    }
}

@include applyResponsive320 {
    .requestForm {
        width: 100%;
        padding: 22px 14px 32px 14px;

        .inputsGroup {
            width: 100%;

            display: -ms-grid;
            display: grid;

            -ms-grid-columns: repeat(1, 100%);
            grid-template-columns: repeat(1, 100%);

            -ms-grid-rows: repeat(6, 45px);
            grid-template-rows: repeat(6, 45px);

            gap: 26px;
            margin-bottom: 31px;
        }

        .warning {
            width: 190px;

            font-size: 10px;
            text-align: center;
        }
    }
}

@include applyResponsive428 {
    .requestForm {
        width: 376px;

        .inputsGroup {
            grid-template-columns: repeat(1, 345px);
        }

        .warning {
            width: 219px;

            font-size: 12px;
            text-align: center;
        }
    }
}

@include applyResponsive512 {
    .requestForm {
        width: 460px;
        padding: 30px 28px 32px 28px;

        .inputsGroup {
            grid-template-columns: repeat(1, 100%);
        }
    }
}

@include applyResponsive768 {
    .requestForm {
        width: 550px;
        padding: 30px 28px 32px 28px;

        .warning {
            width: auto;
        }
    }
}

@include applyResponsive1024 {
    .requestForm {
        width: 900px;
        padding: 40px 35px 37px 35px;

        .inputsGroup {
            width: 100%;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 3fr);

            margin-bottom: 40px;
        }

        .warning {
            font-size: 16px;
        }
    }
}

@include applyResponsive1440 {
    .requestForm {
        width: 1076px;
        padding: 49px 46px;

        .inputsGroup {
            gap: 33px 28px;
            margin-bottom: 55px;
        }
    }
}