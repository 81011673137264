@import 'styles/constants';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;
    border: 1px solid $kashmirBlue;
}

.s2 {
    position: absolute;
    border: 1px solid $white;

    z-index: 1;
}

.s3 {
    position: absolute;
    background: $white;

    z-index: 1;
}

.s4 {
    position: absolute;
    background: $wildBlueYonder;

    z-index: 1;
}

.s5 {
    position: absolute;
    background: $sanJuan;

    z-index: 1;
}

@include applyResponsive320 {
    .s1 {
        display: none;
    }

    .s2 {
        width: 56px;
        height: 9px;

        top: 198px;
        left: 7px;
    }

    .s3 {
        width: 4px;
        height: 4px;

        top: 210px;
        left: 7px;
    }

    .s4 {
        width: 4px;
        height: 4px;

        top: 210px;
        left: 14px;
    }

    .s5 {
        width: 4px;
        height: 4px;

        top: 210px;
        left: 21px;
    }
}

@include applyResponsive428 {
    .s2 {
        width: 80px;
        height: 12px;

        top: 230px;
        left: 17px;
    }

    .s3 {
        width: 5px;
        height: 5px;

        top: 247px;
        left: 17px;
    }

    .s4 {
        width: 5px;
        height: 5px;

        top: 247px;
        left: 26px;
    }

    .s5 {
        width: 5px;
        height: 5px;

        top: 247px;
        left: 35px;
    }
}

@include applyResponsive512 {
    .s2 {
        top: 271px;
        left: 22px;
    }

    .s3 {
        top: 286px;
        left: 22px;
    }

    .s4 {
        top: 286px;
        left: 31px;
    }

    .s5 {
        top: 286px;
        left: 40px;
    }
}

@include applyResponsive768 {
    .s2 {
        top: 363px;
        left: 111px;
    }

    .s3 {
        top: 378px;
        left: 111px;
    }

    .s4 {
        top: 378px;
        left: 120px;
    }

    .s5 {
        top: 378px;
        left: 129px;
    }
}

@include applyResponsive1024 {
    .s1 {
        display: block;

        width: 17px;
        height: 17px;

        top: 89px;
        left: 224px;
    }

    .s2 {
        width: 130px;
        height: 20px;

        top: 340px;
        left: auto;
        right: 36px;
    }

    .s3 {
        width: 8px;
        height: 8px;

        top: 365px;
        left: auto;
        right: 158px;
    }

    .s4 {
        width: 8px;
        height: 8px;

        top: 365px;
        left: auto;
        right: 145px;
    }

    .s5 {
        width: 8px;
        height: 8px;

        top: 365px;
        left: auto;
        right: 132px;
    }
}

@include applyResponsive1280 {
    .s1 {
        top: 120px;
        left: 240px;
    }

    .s2 {
        top: 433px;
        right: 48px;
    }

    .s3 {
        top: 460px;
        right: 170px;
    }

    .s4 {
        top: 460px;
        right: 157px;
    }

    .s5 {
        top: 460px;
        right: 145px;
    }
}

@include applyResponsive1440 {
    .s1 {
        left: 300px;
    }

    .s2 {
        top: 462px;
        right: 113px;
    }

    .s3 {
        top: 487px;
        right: 235px;
    }

    .s4 {
        top: 487px;
        right: 222px;
    }

    .s5 {
        top: 487px;
        right: 209px;
    }
}

@include applyResponsive1920 {
    .s1 {
        top: 138px;
        left: 316px;
    }

    .s2 {
        top: 552px;
        right: 127px;
    }

    .s3 {
        top: 579px;
        right: 249px;
    }

    .s4 {
        top: 579px;
        right: 236px;
    }

    .s5 {
        top: 579px;
        right: 223px;
    }
}