@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.formBlock {
    @include dFlex();
    @include flexColumn();
    @include justifyContentStart();
    @include alignItemsCenter();

    position: relative;

    .title {
        @include fontPlay700();
        text-transform: uppercase;

        z-index: 2;

        .red {
            color: $torchRed;
        }
    }

    .description {
        text-align: center;
        z-index: 1;

        .bold {
            @include fontPlay700();
        }
    }
}

@include applyResponsive320 {
    .formBlock {
        .title {
            font-size: 32px;
            line-height: 37px;

            margin-bottom: 26px;
        }

        .description {
            width: 272px;

            font-size: 12px;
            line-height: 15px;

            margin-bottom: 39px;
        }
    }
}

@include applyResponsive428 {
    .formBlock {
        .description {
            width: 316px;

            font-size: 14px;
            line-height: 17px;
        }
    }
}

@include applyResponsive768 {
    .formBlock {
        .title {
            margin-bottom: 30px;
        }

        .description {
            width: auto;
        }
    }
}

@include applyResponsive1024 {
    .formBlock {
        .description {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

@include applyResponsive1280 {
    .formBlock {
        .title {
            font-size: 40px;
            line-height: 43px;

            margin-bottom: 38px;
        }

        .description {
            font-size: 18px;
            line-height: 21px;

            margin-bottom: 45px;
        }
    }
}

@include applyResponsive1440 {
    .formBlock {
        margin-bottom: 142px;

        .title {
            font-size: 48px;
            line-height: 55px;
        }

        .description {
            font-size: 24px;
            line-height: 27px;

            margin-bottom: 64px;
        }
    }
}