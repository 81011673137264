@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.serviceCard {
    @include dFlex();
    @include flexColumn();
    @include justifyContentAround();

    position: relative;
    background: $ebony;

    z-index: 3;
}

@include applyResponsive320 {
    .serviceCard {
        height: 106px;

        border: 1px solid $sanJuan;
        border-radius: 5px;

        padding: 12px;
        padding-right: 5px;

        .icon {
            width: 34px;
            height: 34px;
        }

        .description {
            font-size: 10px;
            line-height: 12px;
            margin-top: 10px;
        }
    }
}

@include applyResponsive428 {
    .serviceCard {
        height: 106px;
        border: 1px solid $sanJuan;

        padding: 15px 18px;

        .description {
            font-size: 12px;
            line-height: 14px;

            margin-top: 10px;
        }
    }
}

@include applyResponsive768 {
    .serviceCard {
        height: 140px;

        border: 1px solid $sanJuan;
        border-radius: 10px;

        padding: 25px;

        .icon {
            width: 46px;
            height: 46px;
        }

        .description {
            font-size: 16px;
            line-height: 18px;

            margin-top: 10px;
        }
    }
}

@include applyResponsive1440 {
    .serviceCard {
        height: 202px;
        border: 2px solid $sanJuan;

        padding: 35px;

        .icon {
            width: 64px;
            height: 64px;
        }

        .description {
            font-size: 22px;
            line-height: 25px;

            margin-top: 20px;
        }
    }
}