@import 'styles/constants';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;

    width: 15px;
    height: 15px;

    background: $bigStone;

    z-index: 1;
}

.s2 {
    position: absolute;

    width: 15px;
    height: 15px;

    background: $sanJuan;

    z-index: 1;
}

.s3 {
    position: absolute;

    width: 15px;
    height: 15px;

    background: $kashmirBlue;

    z-index: 1;
}

@include applyResponsive320 {
    .s1 {
        width: 10px;
        height: 10px;

        top: 761px;
        left: 0;
    }

    .s2 {
        width: 10px;
        height: 10px;

        top: 761px;
        left: 15px;
    }

    .s3 {
        width: 10px;
        height: 10px;

        top: 761px;
        left: 30px;
    }
}

@include applyResponsive428 {
    .s1 {
        top: 781px;
    }

    .s2 {
        top: 781px;
    }

    .s3 {
        top: 781px;
    }
}


@include applyResponsive768 {
    .s1 {
        display: none;
    }

    .s2 {
        display: none;
    }

    .s3 {
        display: none;
    }
}

@include applyResponsive1024 {
    .s1 {
        display: block;
        left: auto;
        top: 143px;
        right: -10px;
    }

    .s2 {
        display: block;
        left: auto;
        top: 143px;
        right: -25px;
    }

    .s3 {
        display: block;
        left: auto;
        top: 143px;
        right: -40px;
    }
}

@include applyResponsive1440 {
    .s1 {
        width: 15px;
        height: 15px;

        top: 220px;
        right: -10px;
    }

    .s2 {
        width: 15px;
        height: 15px;

        top: 220px;
        right: -35px;
    }

    .s3 {
        width: 15px;
        height: 15px;

        top: 220px;
        right: -60px;
    }
}

@include applyResponsive1920 {
    .s1 {
        top: 243px;
        right: 50px;
    }

    .s2 {
        top: 243px;
        right: 25px;
    }

    .s3 {
        top: 243px;
        right: 0;
    }
}