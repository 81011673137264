@font-face {
    font-weight: 700;
    font-family: 'Play';
    src: url(../fonts/Play-Bold.woff2),
        url(../fonts/Play-Bold.woff);
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-weight: 400;
    font-family: 'Play';
    src: url(../fonts/Play-Regular.woff2),
        url(../fonts/Play-Regular.woff);
    font-display: swap;
    font-style: normal;
}

@mixin fontPlay700() {
    font-family:
        'Play',
        sans-serif;
    font-weight: 700;
}

@mixin fontPlay400() {
    font-family:
        'Play',
        sans-serif;
    font-weight: 400;
}