@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';
@import 'tools/styles/animations';

.header {
    @include dFlex();

    .burger {
        z-index: 39;
    }

    .logo {
        @include dFlex();

        @include fontPlay700();
        text-transform: uppercase;
        white-space: nowrap;
        color: $torchRed;
    }

    .nav {
        @include dFlex();
        @include justifyContentStart();

        .navItem {
            white-space: nowrap;
            color: $wildBlueYonder;

            span {
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;

                &:hover {
                    color: $wildBlueYonderHover;
                    @include easeTransition();
                }
            }
        }
    }
}

@include applyResponsive320 {
    .header {
        @include flexColumn();

        width: 100%;
        max-width: 1406px;

        margin-bottom: 25px;

        .burger {
            position: fixed;

            top: 20px;
            right: 40px;

            margin-bottom: 4px;
        }

        .logo {
            @include justifyContentCenter();

            font-size: 26px;
            line-height: 30px;

            margin-top: 59px;
        }

        .nav {
            display: none !important;
        }
    }
}

@include applyResponsive1024 {
    .header {
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        height: 155px;
        margin-bottom: 6px;

        .burger {
            display: none !important;
        }

        .logo {
            @include justifyContentStart();
            font-size: 32px;
            line-height: 35px;

            margin-top: 0;
        }

        .nav {
            @include dFlex();

            .navItem {
                font-size: 18px;
                line-height: 20px;
                padding: 0 15px;
            }
        }
    }
}

@include applyResponsive1920 {
    .header {
        .logo {
            font-size: 36px;
            line-height: 42px;

            padding-right: 169px;
        }

        .nav {
            padding-right: 168px;

            .navItem {
                font-size: 20px;
                line-height: 23px;
                padding: 0 43px;
            }
        }
    }
}