@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';
@import 'tools/styles/helpers';

.workCard {
    z-index: 1;

    .title {
        width: auto;

        @include fontPlay700();
        text-transform: uppercase;

        span {
            background-color: $sanJuan;
            @include borderRadius(10px 10px 0px 0px);
        }
    }

    .content {
        @include dFlex();
        @include flexRow();
        @include justifyContentStart();
        @include alignItemsCenter();

        position: relative;

        background: $ebony;
        @include borderRadius(10px);

        .number {
            @include fontPlay700();
            color: $torchRed;
        }

        .description {}
    }

}

@include applyResponsive320 {
    .workCard {
        .title {
            font-size: 13px;
            line-height: 16px;

            span {
                padding: 5px 17px 22px;
            }
        }

        .content {
            border: 1px solid $sanJuan;

            padding: 17px 14px 20px 20px;
            margin-top: 5px;

            .number {
                font-size: 55px;
                line-height: 64px;

                margin-right: 20px;
            }

            .description {
                font-size: 13px;
                line-height: 15px;
            }
        }
    }
}

@include applyResponsive428 {
    .workCard {
        max-width: 462px;

        .content {
            padding-right: 17px;
        }
    }
}

@include applyResponsive768 {
    .workCard {
        max-width: 470px;

        .title {
            font-size: 16px;
            line-height: 20px;
        }

        .content {
            .number {
                font-size: 60px;
            }

            .description {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
}

@include applyResponsive1024 {
    .workCard {
        max-width: 444px;

        .title {
            span {
                padding: 8px 28px 20px;
            }
        }

        .content {
            margin-top: 8px;
        }
    }
}

@include applyResponsive1280 {
    .workCard {
        max-width: 485px;
    }
}

@include applyResponsive1440 {
    .workCard {
        max-width: 600px;

        .title {
            font-size: 20px;
            line-height: 27px;

            span {
                padding: 10px 30px 22px;
            }
        }

        .content {
            padding: 36px 17px 30px 42px;
            margin-top: 10px;

            .number {
                font-size: 96px;
                line-height: 111px;

                margin-right: 34px;
            }

            .description {
                font-size: 22px;
                line-height: 25px;
            }
        }
    }
}

@include applyResponsive1920 {
    .workCard {
        max-width: 653px;

        .title {
            font-size: 24px;
        }

        .content {
            padding: 46px 38px 42px 42px;

            .number {
                font-size: 96px;
                margin-right: 34px;
            }

            .description {
                font-size: 22px
            }
        }
    }
}