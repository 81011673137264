@import 'tools/styles/adaptive';

.atom {
    position: absolute;
}

@include applyResponsive320 {
    .atom {
        width: 698px;
        height: 440px;

        top: 203px;
    }
}

@include applyResponsive512 {
    .atom {
        width: 795px;
        height: 501px;

        top: 241px;
    }
}

@include applyResponsive768 {
    .atom {
        width: 1080px;
        height: 681px;

        top: 107px;
    }
}

@include applyResponsive1024 {
    .atom {
        width: 586px;
        height: 369px;

        top: 31px;
    }
}

@include applyResponsive1280 {
    .atom {
        width: 673px;
        height: 424px;

        top: -15px;
    }
}

@include applyResponsive1440 {
    .atom {
        width: 767px;
        height: 483px;

        top: 30px;
    }
}

@include applyResponsive1920 {
    .atom {
        width: 967px;
        height: 609px;

        top: -43px;
    }
}