@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'styles/constants';
@import 'tools/styles/animations';

.menu {
    position: fixed;

    top: 0;
    right: -100%;

    width: 310px;
    height: 100vh;

    background: $ebonyBurgerBg;
    border: 2px solid $sanJuan;

    font-size: 20px;
    line-height: 23px;
    color: $wildBlueYonder;

    padding: 20px 40px;

    z-index: 40;

    .arrow {
        text-align: end;
        margin-bottom: 47px;

        img {
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
        }
    }

    .nav {
        .navItem {
            @include noSelectText();
            margin-bottom: 25px;

            span {
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;

                &:hover {
                    color: $wildBlueYonderHover;
                    @include easeTransition();
                }
            }
        }
    }
}