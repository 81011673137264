@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.workBlock {
    position: relative;

    .titleBlock {
        @include dFlex();

        .title {
            @include fontPlay700();
            text-transform: uppercase;
        }

        .redText {
            text-transform: uppercase;
            color: $torchRed;
        }
    }

    .cards {
        @include dFlex();

        .workCard1 {}

        .workCard2 {}

        .workCard3 {}

        .workCard4 {}

        .workCard5 {}
    }
}

@include applyResponsive320 {
    .workBlock {
        margin-bottom: 97px;

        .titleBlock {
            @include flexColumn();
            @include justifyContentCenter();

            margin-bottom: 43px;

            .title {
                font-size: 30px;
                line-height: 35px;
                text-align: center;

                margin-bottom: 7px;
            }

            .redText {
                font-size: 10px;
                line-height: 11px;
                text-align: center;

                margin-left: 238px;
            }
        }

        .cards {
            @include flexWrap();
            @include justifyContentCenter();

            gap: 35px;
        }
    }
}

@include applyResponsive428 {
    .workBlock {
        .titleBlock {
            .title {
                font-size: 32px;
                line-height: 37px;
            }

            .redText {
                margin-left: 260px;
            }
        }

        .cards {
            gap: 35px;
        }
    }
}

@include applyResponsive1024 {
    .workBlock {
        margin-bottom: 100px;

        .titleBlock {
            @include flexRow();
            @include justifyContentBetween();
            @include alignItemsCenter();

            .redText {
                margin-left: 0;
            }
        }

        .cards {
            .workCard5 {
                max-width: 450px;
            }
        }
    }
}

@include applyResponsive1280 {
    .workBlock {
        margin-bottom: 155px;

        .titleBlock {
            .title {
                font-size: 40px;
            }

            .redText {
                font-size: 13px;
            }
        }

        .cards {
            gap: 42px 57px;
        }
    }
}

@include applyResponsive1440 {
    .workBlock {
        margin-bottom: 209px;

        .titleBlock {
            margin-bottom: 63px;

            .title {
                font-size: 48px;
                line-height: 55px;
            }

            .redText {
                font-size: 13px;
                line-height: 15px;
            }
        }

        .cards {
            gap: 50px 40px;

            .workCard5 {
                max-width: 606px;
            }
        }
    }
}

@include applyResponsive1920 {
    .workBlock {
        margin-bottom: 209px;

        .cards {
            gap: 84px 100px;

            .workCard5 {
                max-width: 657px;
            }
        }
    }
}