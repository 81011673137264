@import 'styles/constants';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.home {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();
    @include justifyContentBetween();

    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    color: $white;
    background: $ebony;

    .content {
        width: 100%;
        max-width: 1406px;
    }
}

@include applyResponsive320 {
    .home {
        padding: 0 10px;
    }
}

@include applyResponsive428 {
    .home {
        padding: 0 25px;
    }
}

@include applyResponsive1024 {
    .home {
        padding: 0 50px;
    }
}

@include applyResponsive1280 {
    .home {
        padding: 0 100px;
    }
}

@include applyResponsive1680 {
    .home {
        padding: 0 150px;
    }
}

@include applyResponsive1920 {
    .home {
        padding: 0 255px;
    }
}