@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.footer {
    @include dFlex();

    .logo {
        @include fontPlay700();
        text-transform: uppercase;
        white-space: nowrap;
        color: $rhino;
    }
}

@include applyResponsive320 {
    .footer {
        @include alignItemsEnd();

        height: 132px;

        padding-top: 130px;
        padding-bottom: 40px;

        .logo {
            font-size: 14px;
        }
    }
}

@include applyResponsive512 {
    .footer {
        .logo {
            font-size: 16px;
        }
    }
}

@include applyResponsive768 {
    .footer {
        .logo {
            font-size: 18px;
        }
    }
}

@include applyResponsive1024 {
    .footer {
        padding-bottom: 52px;

        .logo {
            font-size: 18px;
        }
    }
}

@include applyResponsive1440 {
    .footer {
        padding-top: 0px;

        .logo {
            font-size: 24px;
        }
    }
}