@import 'styles/constants';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;

    height: 1px;
    background: $torchRed;
}

.s2 {
    position: absolute;

    width: 66px;
    height: 1px;

    background: $torchRed;
}

.s3 {
    position: absolute;

    width: 66px;
    height: 1px;

    background: $torchRed;
}

@include applyResponsive320 {
    .s1 {
        width: 177px;

        top: 48px;
        left: 50px;
    }

    .s2 {
        display: none;
    }

    .s3 {
        display: none;
    }
}

@include applyResponsive428 {
    .s1 {
        top: 50px;
        left: 105px;
    }
}

@include applyResponsive512 {
    .s1 {
        left: 149px;
    }
}

@include applyResponsive768 {
    .s1 {
        left: 272px;
    }
}

@include applyResponsive1024 {
    .s1 {
        width: 458px;

        left: auto;
        top: 23px;
        right: 76px;
    }

    .s2 {
        display: block;

        top: 593px;
        left: 134px;
    }

    .s3 {
        display: block;

        top: 593px;
        right: 134px;
    }
}

@include applyResponsive1280 {
    .s1 {
        width: 570px;
        top: 22px;
    }

    .s2 {
        top: 593px;
        left: 199px;
    }

    .s3 {
        top: 593px;
        right: 199px;
    }
}

@include applyResponsive1440 {
    .s1 {
        width: 610px;
        top: 32px;
    }

    .s2 {
        top: 883px;
        left: 199px;
    }

    .s3 {
        top: 883px;
        right: 199px;
    }
}

@include applyResponsive1920 {
    .s1 {
        width: 710px;

        top: 31px;
        right: 132px;
    }

    .s2 {
        top: 988px;
        left: 240px;
    }

    .s3 {
        top: 988px;
        right: 240px;
    }
}