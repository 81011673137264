@import 'styles/constants';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;

    width: 174px;
    height: 7px;

    background: $torchRed;
}

.line {
    @include dFlex();
    position: absolute;

    width: 100%;
    z-index: 0;

    .s2 {
        width: 10px;
        height: 10px;

        border: 1px solid $torchRed;
    }

    .s3 {
        background: $torchRed;
    }

    .s4 {
        width: 10px;
        height: 10px;

        border: 1px solid $torchRed;
    }
}

@include applyResponsive320 {
    .s1 {
        width: 91px;
        margin: 78px 0 0 117px;
    }

    .line {
        @include flexColumn();
        @include justifyContentBetween();
        @include alignItemsCenter();

        margin-top: 135px;

        .s3 {
            width: 1px;
            height: 1243px;
        }
    }
}

@include applyResponsive428 {
    .s1 {
        width: 91px;
        margin: 80px 0 0 117px;
    }
}

@media screen and (min-width: 449px) {
    .s1 {
        margin: 38px 0 0 307px
    }

    .line {
        margin-top: 99px;
    }
}

@include applyResponsive768 {
    .s1 {
        width: 174px;
        margin: 38px 0 0 231px;
    }
}

@include applyResponsive1024 {
    .s1 {
        margin: 42px 0 0 231px;
    }

    .line {
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        margin-top: 253px;

        .s3 {
            width: calc(100% - 20px);
            height: 1px;
        }
    }
}

@include applyResponsive1280 {
    .s1 {
        margin: 46px 0 0 331px;
    }
}

@include applyResponsive1440 {
    .line {
        margin-top: 284px;
    }
}

@include applyResponsive1920 {
    .s1 {
        margin: 54px 0 0 325px;
    }

    .line {
        margin-top: 288px;
    }
}