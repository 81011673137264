@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.clientsBlock {
    @include dFlex();
    @include flexColumn();
    @include justifyContentStart();
    @include alignItemsCenter();

    position: relative;

    .title {
        @include fontPlay700();
        text-transform: uppercase;

        z-index: 1;
    }

    .clients {
        @include dFlex();
    }
}

@include applyResponsive320 {
    .clientsBlock {
        margin-bottom: 98px;

        .title {
            font-size: 32px;
            line-height: 37px;
            text-align: center;

            margin-bottom: 52px;
        }

        .clients {
            @include flexColumn();
            @include justifyContentBetween();
            @include alignItemsCenter();

            gap: 59px;
        }
    }
}

@include applyResponsive512 {
    .clientsBlock {
        .title {
            margin-bottom: 52px;
        }

        .clients {
            gap: 79px;
        }
    }
}

@include applyResponsive1024 {
    .clientsBlock {
        margin-bottom: 200px;

        .clients {
            @include flexRow();
            @include justifyContentCenter();
            @include alignItemsCenter();
        }
    }
}

@include applyResponsive1280 {
    .clientsBlock {
        margin-bottom: 200px;

        .title {
            font-size: 40px;
            line-height: normal;
        }

        .clients {
            gap: 110px;
        }
    }
}

@include applyResponsive1440 {
    .clientsBlock {
        margin-bottom: 281px;

        .title {
            font-size: 48px;
            line-height: 55px;

            margin-bottom: 69px;
        }

        .clients {
            gap: 30px;
        }
    }
}