@import 'tools/styles/adaptive';

.letterI {
    position: absolute;
    z-index: 1;
}

.letterT {
    position: absolute;
    z-index: 1;
}

.redHatch {
    position: absolute;
}

.net {
    position: absolute;
}

@include applyResponsive320 {
    .letterI {
        display: none;
    }

    .letterT {
        display: none;
    }

    .redHatch {
        top: 679px;
        right: -581px;
    }

    .net {
        width: 258px;
        height: 197px;

        top: -25px;
        left: -92px;
    }
}

@include applyResponsive428 {
    .redHatch {
        top: 693px;
        right: -515px;
    }

    .net {
        top: -25px;
        left: -52px;
    }
}

@include applyResponsive768 {
    .redHatch {
        top: 689px;
        right: -451px;
    }

    .net {
        top: 39px;
        left: 19px;
    }
}

@include applyResponsive1024 {
    .letterI {
        display: block;

        top: 194px;
        left: -21px;
    }

    .letterT {
        display: block;

        top: 191px;
        right: -112px;
    }

    .redHatch {
        width: 700px;
        height: 54px;

        top: 545px;
        right: -18px;
    }

    .net {
        top: 55px;
        left: -33px;
    }
}

@include applyResponsive1280 {
    .letterI {
        top: 194px;
        left: -15px;
    }

    .letterT {
        top: 191px;
        right: -87px;
    }

    .redHatch {
        top: 559px;
        right: 30px;
    }

    .net {
        top: 55px;
        left: 47px;
    }
}

@include applyResponsive1440 {
    .letterI {
        top: 290px;
        left: -21px;
    }

    .letterT {
        top: 277px;
        right: -92px;
    }

    .redHatch {
        top: 686px;
        right: 30px;
    }

    .net {
        width: 388px;
        height: 297px;

        top: 54px;
        left: 22px;
    }
}

@include applyResponsive1920 {
    .letterI {
        top: 305px;
        left: 46px;
    }

    .letterT {
        top: 305px;
        right: -40px;
    }

    .redHatch {
        top: 690px;
        right: 74px;
    }

    .net {
        top: 62px;
        left: 99px;
    }
}