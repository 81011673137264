@import 'tools/styles/adaptive';

.greyLine1 {
    position: absolute;
    z-index: 0;
}

@include applyResponsive320 {
    .greyLine1 {
        display: none;
    }
}

@include applyResponsive768 {
    .greyLine1 {
        display: block;

        width: 438px;
        height: 645px;

        top: 78px;
        left: 172px;
    }
}

@include applyResponsive1024 {
    .greyLine1 {
        width: 282px;
        height: 415px;

        top: 84px;
        left: 120px;
    }
}

@include applyResponsive1280 {
    .greyLine1 {
        width: 288px;
        height: 424px;

        top: 80px;
        left: 160px;
    }
}

@include applyResponsive1440 {
    .greyLine1 {
        width: 438px;
        height: 645px;

        top: 101px;
        left: 125px;
    }
}

@include applyResponsive1920 {
    .greyLine1 {
        width: 479px;
        height: 705px;

        top: 105px;
        left: 127px;
    }
}