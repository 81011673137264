@import 'tools/styles/animations';

.burgerMenu {

    .icon {
        img {
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
        }
    }

    .isOpen {
        right: 0;
        @include easeTransition();
    }

    .isClose {
        right: -100%;
        @include easeTransition();
    }
}