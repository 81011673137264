@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/animations';
@import 'tools/styles/adaptive';

.inputForm {
    @include easeTransition();

    width: 100%;
    height: 66px;

    font-size: 20px;
    color: $white;

    background: $bigStoneLight;
    border: 2px solid $sanJuan;
    border-radius: 10px;

    padding: 0 32px;

    &::-webkit-input-placeholder {
        color: $whitePlaceholder;
    }

    &::-moz-placeholder {
        color: $whitePlaceholder;
    }

    &:focus {
        outline: none;
        background: $bigStoneLightFocus;
        border: 2px solid $kashmirBlue;
    }
}

.isError {
    border: 2px solid $torchRed;
}

.errorMessage {
    font-size: 15px;
    color: $torchRed;

    padding-left: 10px;
}

@include applyResponsive320 {
    .inputForm {
        height: 47px;

        font-size: 14px;

        border-radius: 7px;
        padding: 0 23px;
    }
}

@include applyResponsive1024 {
    .inputForm {
        height: 56px;

        font-size: 16px;

        border-radius: 8px;
        padding: 0 26px;
    }
}

@include applyResponsive1440 {
    .inputForm {
        height: 66px;

        font-size: 20px;

        border-radius: 10px;
        padding: 0 32px;
    }
}