@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.projectBlock {
    @include dFlex();
    @include flexColumn();
    @include justifyContentBetween();
    @include alignItemsCenter();

    position: relative;

    .title {
        @include fontPlay700();
        text-transform: uppercase;
        text-align: center;
    }

    .whiteLine {
        background: $white;
        z-index: 4;
    }

    .cards {
        @include dFlex();
    }
}

@include applyResponsive320 {
    .projectBlock {
        margin-bottom: 180px;

        .title {
            width: 300px;

            font-size: 32px;
            line-height: 37px;

            margin-bottom: 14px;
        }

        .whiteLine {
            width: 43px;
            height: 1px;

            margin-right: 166px;
            margin-bottom: 94px;
        }

        .cards {
            @include flexColumn();
            @include justifyContentBetween();
            @include alignItemsCenter();

            gap: 35px;
        }
    }
}

@include applyResponsive428 {
    .projectBlock {
        .title {
            width: auto;
        }
    }
}

@media screen and (min-width: 449px) {
    .projectBlock {
        .whiteLine {
            margin-right: 363px;
        }
    }
}

@include applyResponsive1024 {
    .projectBlock {
        margin-bottom: 130px;

        .title {
            margin-bottom: 22px;
        }

        .whiteLine {
            width: 70px;
            height: 1px;

            margin-right: 330px;
            margin-bottom: 36px;
        }

        .cards {
            @include flexRow();
            @include justifyContentCenter();

            gap: 20px;
        }
    }
}

@include applyResponsive1280 {
    .projectBlock {
        margin-bottom: 155px;

        .title {
            font-size: 40px;
            line-height: normal;
        }

        .whiteLine {
            margin-right: 425px;
        }

        .cards {
            gap: 35px;
        }
    }
}

@include applyResponsive1440 {
    .projectBlock {
        margin-bottom: 225px;

        .projectBlock {
            .title {
                font-size: 48px;
                line-height: 55px;

                margin-bottom: 32px;
            }

            .whiteLine {
                margin-bottom: 89px;
                margin-right: 525px;
            }

            .cards {
                gap: 51px;
            }
        }
    }
}