@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.serviceBlock {
    @include dFlex();
    position: relative;

    .main {
        .title {
            @include fontPlay700();
            text-transform: uppercase;
        }

        .description {}

        .upperGrayText {
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: $kashmirBlue;

            margin-left: 80px;
        }
    }

    .cardsContainer {
        .cards {
            @include dFlex();
            @include flexWrap();
            @include justifyContentEnd();

            .serviceCard1 {
                width: 48%;
            }

            .serviceCard2 {
                width: 48%;
            }

            .serviceCard3 {
                width: 58%;
            }

            .serviceCard4 {
                width: 38%;
            }
        }

        .lowerGrayText {
            font-size: 10px;
            line-height: 11px;
            text-align: end;
            color: $kashmirBlue;
        }
    }
}

@include applyResponsive320 {
    .serviceBlock {
        @include flexColumn();
        @include justifyContentStart();
        @include alignItemsCenter();

        margin-bottom: 82px;

        .main {
            text-align: center;
            margin-bottom: 30px;

            .title {
                font-size: 30px;
                line-height: 37px;

                margin-bottom: 33px;
            }

            .description {
                font-size: 14px;
                line-height: 17px;

                p {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            .upperGrayText {
                display: none;
            }
        }

        .cardsContainer {
            max-width: 342px;

            .cards {
                gap: 8px 10px;
                margin-bottom: 65px;
            }
        }
    }
}

@include applyResponsive428 {
    .serviceBlock {
        .main {
            margin-bottom: 35px;

            .title {
                font-size: 32px;
                margin-bottom: 38px;
            }

            .description {
                font-size: 15px;
            }
        }

        .cardsContainer {
            max-width: 450px;

            .cards {
                gap: 10px 12px;
                margin-bottom: 73px;
            }
        }
    }
}

@include applyResponsive768 {
    .serviceBlock {
        .main {
            .description {
                font-size: 16px;
            }
        }

        .cardsContainer {
            max-width: 606px;

            .cards {
                gap: 18px 20px;
                margin-bottom: 85px;
            }
        }
    }
}

@include applyResponsive1024 {
    .serviceBlock {
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsStart();

        .main {
            text-align: start;

            .description {
                line-height: 18px;
                margin-bottom: 80px;
            }

            .upperGrayText {
                display: block;
                font-size: 11px;
            }
        }

        .cardsContainer {
            max-width: 550px;

            .cards {
                margin-bottom: 30px;
            }

            .lowerGrayText {
                display: none;
            }
        }
    }
}

@include applyResponsive1194 {
    .serviceBlock {
        .cardsContainer {
            max-width: 640px;
        }
    }
}

@include applyResponsive1280 {
    .serviceBlock {
        margin-bottom: 100px;

        .main {
            .title {
                font-size: 40px;
            }

            .description {
                font-size: 18px;
                line-height: 20px;

                margin-bottom: 70px;
            }

            .upperGrayText {
                font-size: 12px;
            }
        }

        .cardsContainer {
            max-width: 620px;
        }
    }
}

@include applyResponsive1440 {
    .serviceBlock {
        margin-bottom: 200px;

        .main {
            .title {
                font-size: 48px;
                line-height: 55px;
            }

            .description {
                font-size: 24px;
                line-height: 27px;

                margin-bottom: 135px;
            }

            .upperGrayText {
                font-size: 13px;
            }
        }

        .cardsContainer {
            max-width: 718px;

            .cards {
                gap: 22px 24px;
            }
        }
    }
}

@include applyResponsive1920 {
    .serviceBlock {
        .cardsContainer {
            max-width: 724px;
        }
    }
}