@import 'styles/constants';
@import 'styles/fonts';
@import 'tools/styles/flex';
@import 'tools/styles/adaptive';
@import 'tools/styles/animations';

.primaryButton {
    @include fontPlay700();
    @include dFlex();
    @include flexRow();
    @include justifyContentCenter();
    @include alignItemsCenter();

    background: $torchRed;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    color: $white;

    &:hover {
        background: $torchRedHover;
        @include easeTransition();
    }
}

@include applyResponsive320 {
    .primaryButton {
        width: 186px;
        height: 44px;

        font-size: 14px;
        border-radius: 6px;
    }
}

@include applyResponsive1024 {
    .primaryButton {
        width: 255px;
        height: 56px;

        font-size: 18px;
        border-radius: 8px;
    }
}

@include applyResponsive1440 {
    .primaryButton {
        width: 275px;
        height: 66px;

        font-size: 20px;
        border-radius: 10px;
    }
}