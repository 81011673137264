@import 'styles/constants';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;

    height: 1px;
    background: $torchRed;
}

@include applyResponsive320 {
    .s1 {
        width: 100px;

        top: 39px;
        left: -20px;
    }
}

@include applyResponsive428 {
    .s1 {
        width: 100px;

        top: 33px;
        left: 0px;
    }
}

@include applyResponsive512 {
    .s1 {
        width: 148px;

        top: 27px;
        left: -5px;
    }
}

@include applyResponsive768 {
    .s1 {
        width: 229px;

        top: 27px;
        left: 28px;
    }
}

@include applyResponsive1024 {
    .s1 {
        width: 199px;

        top: 27px;
        left: 175px;
    }
}

@include applyResponsive1440 {
    .s1 {
        width: 265px;

        top: 27px;
        left: 217px;
    }
}

@include applyResponsive1920 {
    .s1 {
        top: 30px;
        left: 273px;
    }
}