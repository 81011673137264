@import 'styles/fonts';

* {
    box-sizing: border-box;
}

body {
    margin: 0;

    @include fontPlay400();

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}