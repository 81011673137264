.coloredImage {
    .content {
        position: relative;

        .image {
            position: absolute;

            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            z-index: 1;
            mix-blend-mode: multiply;
        }

        .background {
            position: absolute;

            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}