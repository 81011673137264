@import 'styles/constants';
@import 'tools/styles/adaptive';

.s1 {
    position: absolute;

    width: 1px;
    background: $kashmirBlue;
}

@include applyResponsive320 {
    .s1 {
        height: 100px;

        top: 0;
        right: 20px;
    }
}

@include applyResponsive512 {
    .s1 {
        top: 38px;
        right: 51px;
    }
}

@include applyResponsive768 {
    .s1 {
        height: 150px;

        top: 84px;
        right: 124px;
    }
}

@include applyResponsive1024 {
    .s1 {
        right: auto;

        top: 125px;
        left: 360px;
    }
}

@include applyResponsive1280 {
    .s1 {
        left: 409px;
    }
}

@include applyResponsive1440 {
    .s1 {
        top: 243px;
        left: 460px;
    }
}

@include applyResponsive1920 {
    .s1 {
        top: 266px;
        left: 524px;
    }
}